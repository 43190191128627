import { DateTime } from 'luxon';

const convertISOToSeconds = (dateStr) => {
    if (!dateStr) {
        return null;
    }

    // Convert date string to ISO format
    const isoDateStr = dateStr.includes(' ')
        ? dateStr.replace(' ', 'T')
        : `${dateStr}T00:00:00`;

    // Create DateTime object from ISO string and convert to seconds
    return DateTime.fromISO(isoDateStr).toSeconds();
};

export default convertISOToSeconds